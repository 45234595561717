<template>
  <div class="dates">
    <!-- <a href="https://www.songkick.com/artists/9601444" class="songkick-widget" data-theme="dark" data-track-button="on" data-detect-style="true" data-font-color="#ffffff" data-background-color="transparent" data-locale="en">Indecent Behavior tour dates</a>
    <component :is="'script'" src="//widget.songkick.com/9601444/widget.js" async></component>
     -->
    <div class="date" v-for="(date, i) in dates" :key="i">
      <div style="font-size: 12px; margin-bottom: 8px">
        {{date.day}} {{date.date}} <span style="opacity: 0.5" v-if="date.time !== ''">• {{date.time}}</span>
      </div>
      <div style="font-size: 18px; margin-bottom: 4px">
        {{ date.city }}
      </div>
      <div style="font-size: 14px;">
        {{ date.club }}
      </div>
      <v-btn v-if="$vuetify.breakpoint.smAndUp && !date.soldout" @click="navigate(date.tickets)" style="position: absolute; right: 30px; top: 35px; text-transform: none; padding: 0 40px">
        Tickets
      </v-btn>
      <v-btn v-if="$vuetify.breakpoint.xs && !date.soldout" @click="navigate(date.tickets)" style="text-transform: none; width: 100%; margin-top: 20px">
        Tickets
      </v-btn>
      <span v-if="$vuetify.breakpoint.smAndUp && date.soldout" style="font-size: 20px; font-weight: 600; position: absolute; right: 8px; top: 45px; text-transform: none; padding: 0 40px; color: red">
        SOLD OUT
      </span>
      <div v-if="$vuetify.breakpoint.xs && date.soldout" style="font-size: 20px; font-weight: 600; text-transform: none; width: 100%; margin-top: 10px; color: red; text-align: center">
        SOLD OUT
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      dates: [
      {
        day: 'SUN',
        date: '29 JUNE 2025',
        time: '',
        city: 'MOOS MEADOW FESTIVAL',
        club: 'Feuchtwangen, Germany',
        tickets: 'https://meadowfestival.reservix.de/events',
        soldout: false
      },
      {
        day: 'SAT',
        date: '26 JULY 2025',
        time: '',
        city: 'RIEZ OPEN AIR',
        club: 'Bausendorf, Germany',
        tickets: 'https://riez.de/tickets/',
        soldout: false
      },
      {
        day: 'WED - SAT',
        date: '06 - 10 AUGUST 2025',
        time: '',
        city: 'OPEN FLAIR FESTIVAL',
        club: 'Eschwege, Germany',
        tickets: 'https://shop.open-flair.de/produkte/376-tickets-open-flair-festival-2025-eschwege-eschwege-am-06-08-2025',
        soldout: false
      },
      {
        day: 'SUN',
        date: '21 DECEMBER 2025',
        time: '',
        city: 'Saarbrücken, Germany',
        club: 'Garage - Feels Like Home 2025',
        tickets: 'https://www.eventim.de/event/indecent-behavior-garage-19577817/?srsltid=AfmBOoqQ5UCHHfs3MnUTM1JW8W5A5YKbaEC2vk-JMmdaCukTZmyHivAq',
        soldout: false
      },
      ]
    }
  },
  methods: {
    navigate (link) {
      window.open(link, '_blank')
    }
  }
}
</script>

<style scoped>
@media only screen and (min-width: 64.963em) {
  .dates {
    padding: 100px 5%;
    background-image: url('~@/assets/BSPark.jpg');
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    color: white;
  } 
}

.dates {
  padding: 100px 5%;
  color: white;
}

.dates::before {
    content: ' ';
    display: block;
    background-image: url('~@/assets/BSPark.jpg');
    background-position: center;
    background-size: cover;
    height: 100vh; width: 100vw;
    top: 0; bottom: 0; left: 0; right: 0;
    position: fixed;
    z-index: -10;
}

.date {
  width: 100%;
  max-width: 1000px;
  background-color: #0000008a;
  padding: 25px;
  text-align: left;
  position: relative;
  margin: 10px auto;
  border-radius: 5px
}
</style>
